.downloadApp {
    .appStoreSectionD {
        cursor: pointer;
        margin: auto;
        border-radius: 50px;
        background-image: linear-gradient(60deg, #ec34f7 0%, #ec34f7 18%, rgba(236, 52, 247, 0) 100%);
        background-color: blueviolet;
        // padding: 12px 10px 12px 14px;
        // box-shadow: 0 8px 15px rgb(0 0 0 / 10%);
        -webkit-appearance: none;
        label {
            font-family: "BROMEGASEMIBOLD";
            cursor: pointer;
        }
        img {
            cursor: pointer;
        }
    }
}

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
    .afterDownloadButtonHomeOthers {
        padding-top: 0px !important;
    }
    .appStoreSectionD {
        margin-top: 8px;
        width: 92% !important;
        padding: 10px 12px 10px 8px;
        margin: auto;
        label {
            font-size: 24px;
        }
        img {
            width: 32px;
        }
    }
}

// Small devices (landscape phones, less than 768px)
@media (min-width: 575.98px) and (max-width: 767.98px) {
    .afterDownloadButtonHomeOthers {
        padding-top: 0px !important;
    }
    .appStoreSectionD {
        width: 270px;
        padding: 10px 12px;
        margin: auto;
        label {
            font-size: 21px;
        }
        img {
            width: 34px;
        }
    }
}

// Medium devices (tablets, less than 992px)
@media (min-width: 767.98px) and (max-width: 991.98px) {
    .appStoreSectionD {
        width: 230px;
        padding: 10px 12px 10px 5px;
        margin: auto;
        label {
            font-size: 18px;
        }
        img {
            width: 30px;
        }
    }
}

// Large devices (desktops, less than 1200px)
@media (min-width: 991.98px) and (max-width: 1199.98px) {
    .appStoreSectionD {
        width: 290px;
        padding: 10px 12px;
        margin-right: 10px !important;
        label {
            font-size: 26px;
        }
        img {
            width: 30px;
        }
    }
}

// X-Large devices (large desktops, less than 1400px)
@media (min-width: 1199.98px) and (max-width: 1399.98px) {
    .downloadApp {
        margin-top: -10px !important;
    }
    .appStoreSectionD {
        width: 222px;
        padding: 10px 16px 10px 2px;
        margin-right: 10px !important;
        label {
            font-size: 16px;
        }
        img {
            width: 30px;
        }
    }
}

@media (min-width: 1399.98px) {
    .downloadApp {
        margin-top: -10px !important;
    }
    .appStoreSectionD {
        width: 250px;
        padding: 8px 16px 8px 2px;
        margin-right: 10px !important;
        label {
            font-size: 19px;
        }
        img {
            width: 32px;
        }
    }
}

.afterDownloadButtonHomeOthers {
    // padding: 0 !important;
    background-color: #fff;
    padding: 19px 0 16px;
    // transition: all 0.6s cubic-bezier(0.19, 1, 0.22, 1);
    box-shadow: 0 1px 3px rgb(0 0 0 / 10%);
    z-index: 10;
    padding-bottom: 15px !important;
    background-image: linear-gradient(to bottom right, #fff, #faf0fe);
}
