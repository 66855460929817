// @font-face {
//     font-family: "BROMEGABOLDBLACK";
//     src: url("./assests/fonts/BROmega-Black.ttf");
//     font-display: block;
// }
@font-face {
    font-family: "BROMEGABOLD";
    src: url("./../public/fonts/BROMEGABOLD.woff2") format("woff2"),
        url("./../public/fonts/BROMEGABOLD.woff") format("truetype");
    font-display: block;
}
@font-face {
    font-family: "BROMEGASEMIBOLD";
    src: url("./../public/fonts/BROMEGASEMIBOLD.woff2") format("woff2"),
        url("./../public/fonts/BROMEGASEMIBOLD.woff") format("truetype");
    font-display: block;
}
@font-face {
    font-family: "BROMEGAMEDIUM";
    src: url("./../public/fonts/BROMEGAMEDIUM.woff2") format("woff2"),
        url("./../public/fonts/BROMEGAMEDIUM.woff") format("truetype");
    font-display: block;
}
@font-face {
    font-family: "BROMEGAREGULAR";
    src: url("./../public/fonts/BROMEGAREGULAR.woff2") format("woff2"),
        url("./../public/fonts/BROMEGAREGULAR.woff") format("truetype");
    font-display: block; /* Fix flickering */
}

// @font-face {
//     font-family: "BROMEGABOLD";
//     src: url("./assests/fonts/BROMEGABOLD.woff2") format("woff2");
//     font-display: swap;
// }
// @font-face {
//     font-family: "BROMEGASEMIBOLD";
//     src: url("./assests/fonts/BROMEGASEMIBOLD.woff2") format("woff2");
//     font-display: swap;
// }
// @font-face {
//     font-family: "BROMEGAMEDIUM";
//     src: url("./assests/fonts/BROMEGAMEDIUM.woff2") format("woff2");
//     font-display: swap;
// }
// @font-face {
//     font-family: "BROMEGAREGULAR";
//     src: url("./assests/fonts/BROMEGAREGULAR.woff2") format("woff2");
//     font-display: swap; /* Fix flickering */
// }

.modal-backdrop {
    opacity: 0.7 !important;
}
.modal-transition {
    transition: all 0.4s ease;
}

.subTitleMain {
    font-family: "BROMEGABOLD";
    font-size: 50px;
    margin-bottom: 30px;
    color: #333333;
    @media screen and (max-width: 991px) {
        font-size: 35px;
    }
    @media screen and (max-width: 991px) {
        margin-bottom: 16px;
    }
}

@media (min-width: 1900px) and (max-width: 1199.98px) {
    .container {
        max-width: 1200px;
    }
}
@media (min-width: 1399.98px) {
}

.ms-6 {
    margin-left: 3.5rem;
}
