footer {
    background-color: #393939;
    .quickLinks a {
        font-family: "BROMEGAMEDIUM";
        color: whitesmoke;
        text-decoration: none;
        font-size: 18px !important;
    }
    img {
        width: 35px;
        height: 35px;
        margin-right: 20px;
    }
}
