.sideMenu {
    width: 100%;
    // .modal-content {
    height: 100vh;
    border-radius: 0;
    margin-right: 0;
    background: #fff;
    width: 100%;
    .logo {
        width: 120px;
    }
    .close {
        position: absolute;
        right: 20px;
        top: 16px;
        opacity: 1;
        border-radius: 15px;
        border: 0;
        outline: none;
        color: #000;
    }
    .offcanvas-body {
        margin: initial;
        overflow: auto;
        height: calc(100vh - 60px);
        .navHeader {
            overflow: auto;
            .mbl-icon-cls {
                width: 30px;
            }
            .d-flex {
                border-bottom: 1px solid rgb(128 128 128 / 17%);
            }
            a {
                margin: 0;
            }
        }
        .menuLink {
            .imgBg {
                background-color: #eadcf5;
                border-radius: 8px;
                width: 40px;
                height: 40px;
                margin-right: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                img {
                    width: 24px;
                    height: 24px;
                }
            }
            p {
                display: flex;
                align-items: center;
            }
        }
        a {
            font-size: 16px;
            color: #565555;
            text-decoration: none;
            font-family: "BROMEGAREGULAR";
        }
        .testSection {
            height: calc(100vh - 120px);
            overflow: auto;
            p {
                margin-bottom: 15px;
                @media (max-width: 768px) {
                    font-size: 14px;
                }
            }
        }
        .headerFooter {
            label {
                color: #565555;
                font-family: "BROMEGAREGULAR";
            }
            a {
                text-decoration: none;
                color: #952ce4;
                font-family: "BROMEGAMEDIUM";
                font-size: 20px;
                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
}
// }
