.policies {
    a {
        text-decoration: none;
    }
    .mainheading {
        font-size: 35px;
        font-family: "BROMEGABOLD";
        margin-bottom: 4px;
        text-align: center;
        color: #333333;
    }

    .lastUpdate {
        font-family: "BROMEGAMEDIUM";
        color: #000;
        text-align: center;
    }

    .subHeading {
        font-size: 25px;
        font-family: "BROMEGABOLD";
        margin-bottom: 10px;
    }

    .pText {
        font-family: "BROMEGAREGULAR";
        font-size: 18px;
        margin-bottom: 4px;
        color: #696969;
        letter-spacing: -0.4px;
    }

    ul {
        padding-left: 20px;
        margin-top: 8px;

        li {
            font-size: 18px;
            color: #323232;
            font-family: "BROMEGASEMIBOLD";

            ol {
                margin-top: 10px;
                li {
                    font-family: "BROMEGAREGULAR";
                    margin-bottom: 10px;
                    font-size: 17px;
                    color: #696969;
                }
            }
        }
    }

    a {
        color: #c328fb;
    }

    .accordion {
        margin-bottom: 10px;
        border-bottom: 1px solid #d1cece70;
    }

    .accordion-button:focus,
    .accordion-button:active {
        box-shadow: none;
    }

    .accordion-button:not(.collapsed) {
        color: #212529;
        box-shadow: none;
        background-color: transparent;
        font-family: "BROMEGABOLD";
    }

    .accordion-header {
        margin-bottom: 0;
    }

    .accordion-body {
        p {
            margin: 0;
        }
    }

    .accordion {
        button {
            font-family: "BROMEGAREGULAR";
        }
        .accordion-body {
            color: #696969;
            font-family: "BROMEGAREGULAR";
            padding-top: 0;
            b {
                font-family: "BROMEGAMEDIUM";
            }
        }
    }
}
