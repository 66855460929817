.promise {
    padding: 40px 0 50px 0;
    background-color: #f8eff9;
    h5 {
        font-size: 20px;
        padding: 10px;
        @media screen and (max-width: 991px) {
            font-size: 18px;
        }
    }

    img {
        width: 60%;
        transition: 0.3s;
        // height: 126px;
    }

    .promiseCard {
        border-radius: 20px;
        filter: drop-shadow(0px 30px 31px rgba(160, 127, 196, 0.17));
        background-image: linear-gradient(0deg, #f7eaff, #fff);
        border: 1px solid #b864e0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-family: "BROMEGAMEDIUM";
        h5 {
            color: #6e5858;
        }
        &:hover {
            img {
                transform: translateY(-0.25rem);
            }
        }
    }

    .mobilePromises {
        grid-template-columns: 1fr 1fr;
    }

    .desktopPromises {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}

@media (min-width: 447px) and (max-width: 767px) {
    .promise {
        img {
            width: 40%;
        }
    }
}

@media screen and (max-width: 991px) {
    .promise {
        padding: 20px 0 30px 0;
        .promiseCard {
            border-radius: 15px;
        }
    }
}

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  
}

// Small devices (landscape phones, less than 768px)
@media (min-width: 575.98px) and (max-width: 767.98px) {
 
}

// Medium devices (tablets, less than 992px)
@media (min-width: 767.98px) and (max-width: 991.98px) {
    .promise {
        .promiseCard {
            height: 200px;
        }
    }
}

// Large devices (desktops, less than 1200px)
@media (min-width: 991.98px) and (max-width: 1199.98px) {
    .promise {
        .promiseCard {
            height: 240px;
        }
    }
}

// X-Large devices (large desktops, less than 1400px)
@media (min-width: 1199.98px) and (max-width: 1399.98px) {
    .promise {
        .promiseCard {
            height: 260px;
            h5 {
                font-size: 20px;
            }
        }
    }
}

@media (min-width: 1399.98px) {
    .promise {
        margin-top: 18px;
        .promiseCard {
            height: 300px;
            h5 {
                font-size: 22px;
            }
        }
    }
}
