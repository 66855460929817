.playIcons {
    @-webkit-keyframes hvr-push {
        50% {
            -webkit-transform: scale(0.95);
            transform: scale(0.95);
        }
        100% {
            -webkit-transform: scale(1);
            transform: scale(1);
        }
    }
    @keyframes hvr-push {
        50% {
            -webkit-transform: scale(0.95);
            transform: scale(0.95);
        }
        100% {
            -webkit-transform: scale(1);
            transform: scale(1);
        }
    }
    .hvr-push {
        display: inline-block;
        vertical-align: middle;
        -webkit-transform: perspective(1px) translateZ(0);
        transform: perspective(1px) translateZ(0);
        img {
            box-shadow: 0px 8px 15px rgb(64 63 64 / 15%);
            border-radius: 10px;
        }
    }
    .hvr-push:hover,
    .hvr-push:focus,
    .hvr-push:active {
        -webkit-animation-name: hvr-push;
        animation-name: hvr-push;
        -webkit-animation-duration: 0.3s;
        animation-duration: 0.3s;
        -webkit-animation-timing-function: linear;
        animation-timing-function: linear;
        -webkit-animation-iteration-count: 1;
        animation-iteration-count: 1;
    }
}

.grecaptcha-badge {
    visibility: hidden;
}

.appStoreSection {
    cursor: pointer;
    // width: 400px;
    border-radius: 50px;
    background-image: linear-gradient(60deg, #ec34f7 0%, #ec34f7 18%, rgba(236, 52, 247, 0) 100%);
    background-color: blueviolet;
    // padding: 12px 10px 12px 14px;
    box-shadow: 0 8px 15px rgb(0 0 0 / 10%);

    label {
        font-family: "BROMEGASEMIBOLD";
        font-size: 33px;
        cursor: pointer;
    }
    img {
        width: 40px;
        height: 40px;
        cursor: pointer;
    }
}

.downloadBtnPopup {
    .modal-content {
        border-radius: 20px;
        // padding: s 0 30px 0;
        // width: 400pxs;
        padding-top: 16px;

        @media screen and (max-width: 400px) {
            width: 93%;
            margin: auto;
        }

        .modal-header {
            border-bottom: none;
            display: flex;
            justify-content: center;
            padding-bottom: 0;
            padding-top: 0;
        }
        .modal-body {
            padding: 20px;
        }
        a {
            outline: none;
        }
        svg {
            top: 22px;
            position: absolute;
            right: 25px;
            cursor: pointer;
            @media screen and (max-width: 400px) {
                top: 18px;
                width: 18px;
            }
        }
        img {
            width: 200px;
        }
        input {
            box-shadow: none;
            border: 1.5px solid #8645f8ab;
            width: 96%;
            border-radius: 10px;
        }
        p {
            font-family: "BROMEGAREGULAR";
            font-size: 18px;
            color: #333333;
            letter-spacing: -0.4px;
            // margin-bottom: 18px !important;
            @media screen and (max-width: 400px) {
                font-size: 18px;
                // margin-bottom: 12px !important;
            }
        }
        h4 {
            font-family: "BROMEGABOLD";
            font-size: 26px;
            margin-bottom: 0;
            color: #333333;
            @media screen and (max-width: 400px) {
                font-size: 22px;
            }
            // margin-top: 14px;
        }
        button {
            margin-top: 18px;
            border-radius: 50px;
            background-image: linear-gradient(60deg, #ec34f7 0%, #ec34f7 18%, rgba(236, 52, 247, 0) 100%);
            background-color: blueviolet;
            padding: 12px 10px;
            width: 160px;
            border: none;
            color: #fff;
            font-size: 18px;
            font-family: "BROMEGAMEDIUM";
            @media screen and (max-width: 400px) {
                // margin-top: 10px;
                font-size: 16px;
                padding: 10px 6px;
                width: 150px;
            }
        }
    }
}

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
    .appStoreSection {
        width: 280px;
        padding: 10px 12px;
        margin: auto;
        label {
            font-size: 22px;
        }
        img {
            width: 34px;
            height: 34px;
        }
    }
}

// Small devices (landscape phones, less than 768px)
@media (min-width: 575.98px) and (max-width: 767.98px) {
    .appStoreSection {
        width: 260px;
        padding: 10px 12px;
        margin: auto;
        label {
            font-size: 20px;
        }
        img {
            width: 34px;
            height: 34px;
        }
    }
}

// Medium devices (tablets, less than 992px)
@media (min-width: 767.98px) and (max-width: 991.98px) {
    .appStoreSection {
        width: 100%;
        padding: 10px 12px;
        label {
            font-size: 20px;
        }
        img {
            width: 34px;
            height: 34px;
        }
    }
}

// Large devices (desktops, less than 1200px)
@media (min-width: 991.98px) and (max-width: 1199.98px) {
    .appStoreSection {
        width: 100%;
        padding: 12px 10px 12px 14px;
        label {
            font-size: 26px;
        }
        img {
            width: 40px;
            height: 40px;
        }
    }
}

// X-Large devices (large desktops, less than 1400px)
@media (min-width: 1199.98px) and (max-width: 1399.98px) {
    .appStoreSection {
        width: 100%;
        padding: 12px 10px 12px 14px;
        label {
            font-size: 26px;
        }
        img {
            width: 40px;
            height: 40px;
        }
    }
}

@media (min-width: 1399.98px) {
    .appStoreSection {
        width: 100%;
        padding: 12px 12px 12px 18px;
        label {
            font-size: 32px;
        }
    }
}
