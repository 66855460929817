.featuredGames {
    padding: 40px 0 50px 0;
    @media screen and (max-width: 991px) {
        padding: 20px 0 30px 0;
    }
    .featureGamesGrid,
    .featureMobileGamesGrid,
    .featureTabletGamesGrid {
        display: grid;
    }
    .featureGamesGrid {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        gap: 15px;
        justify-items: center;
        align-items: center;
    }
    .featureMobileGamesGrid {
        grid-template-columns: 1fr 1fr;
        gap: 15px;
    }
    .featureTabletGamesGrid {
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 15px;
    }
    img {
        border-radius: 20px;
        -webkit-transform: perspective(1px) translateZ(0);
        transform: perspective(1px) translateZ(0);
        position: relative;
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
        -webkit-transition-property: transform;
        transition-property: transform;
        padding: 0;
        // height: 270px;
        filter: drop-shadow(0px 10px 13.5px rgba(0, 0, 0, 0.19));
        &:hover {
            transform: translateY(-5px);
        }
    }
}

@media screen and (max-width: 991px) {
    .featuredGames {
        img {
            border-radius: 15px;
        }
    }
}

@media screen and (min-width: 500px) {
    .featuredGames {
        margin-top: 116px;
    }
}

