.header {
    .logo {
        width: 170px;
    }
    .litePaper {
        color: #fff;
        border-radius: 63px;
        padding: 6px 22px 8px;
        text-decoration: none;
        border: 2px solid #fff;
        font-size: 18px;
        font-weight: 600;
        margin-top: -10px;
    }
    a {
        text-decoration: none;
    }
    .navLinks {
        a,
        p {
            color: #fff;
            font-size: 20px;
            font-family: "BROMEGAREGULAR";
            &:hover {
                color: whitesmoke;
            }
        }
    }
}
.main {
    top: 20px;
}

video {
    width: 73%;
    border-radius: 34px;
    box-shadow: #0000006b 0px 7px 29px 0px;
}

@media (max-width: 575.98px) {
    .main {
        top: 4px;
    }
}

.headerMain {
    .symbol {
        position: absolute;
        top: -90px;
        opacity: 0.05;
        left: 5%;
        width: 43%;
    }
    .downloadBtn {
        .bg-white {
            border-radius: 45px;
            padding: 8px 9px 8px 9px;
        }
    }

    .heroSection {
        display: grid;
        .heroImage {
            margin: auto;
        }
        .signupTitle {
            font-family: "BROMEGAMEDIUM";
            color: #fff;
        }
        .mainTitle {
            font-family: "BROMEGABOLD";
            margin-left: -3px;
        }
        .usdc {
            color: #fff;
            font-weight: bold;
        }
    }

    .gradient-background {
        background: linear-gradient(36deg, #6ec3f4, #3a3aff, #ff61ab, #e63946);
        background-size: 300% 300%;
        animation: gradient-animation 8s cubic-bezier(0.8, 1.23, 0.3, 0.52) infinite;
        clip-path: polygon(25% 0%, 100% 0, 100% 50%, 100% 59%, 0 100%, 0 0);
    }

    @keyframes gradient-animation {
        0% {
            background-position: 0% 50%;
        }
        50% {
            background-position: 100% 50%;
        }
        100% {
            background-position: 0% 50%;
        }
    }
}

@media (min-width: 1900.98px) {
    .aboutSection {
        ul {
            li,
            b {
                font-size: 22px !important;
            }
        }
        p {
            font-size: 22px !important;
        }
    }
    .container {
        max-width: 1060px;
    }
    .gradient-background {
        height: 530px;
    }
    .downloadBtn {
        width: 410px;
        .bg-white {
            margin-top: 30px;
        }
    }
    .header {
        padding: 10px 0px;
    }
    .appStoreSection {
        padding: 12px 12px 12px 14px;
        img {
            width: 36px;
            height: 36px;
        }
        label {
            font-size: 33px;
        }
    }
    .heroSection {
        grid-template-columns: 54% 46%;
        .heroImage {
            width: 100%;
        }
        .mainTitle {
            font-size: 58px;
            margin-bottom: 20px;
        }
        .signupTitle {
            font-size: 30px;
        }
        .usdc {
            font-size: 32px;
        }
        .socialMediaSection {
            margin-top: 32px;
        }
    }
}

@media (min-width: 1399.98px) and (max-width: 1900.98px) {
    // .aboutSection {
    //     p {
    //         font-size: 22px !important;
    //     }
    //     ul li {
    //         font-size: 22px !important;
    //     }
    //     ul b {
    //         font-size: 22px !important;
    //     }
    // }
    .container {
        max-width: 1060px;
    }
    .gradient-background {
        height: 490px;
    }
    .downloadBtn {
        width: 410px;
        .bg-white {
            margin-top: 30px;
        }
    }
    .header {
        padding: 10px 0px;
    }
    .appStoreSection {
        padding: 12px 12px 12px 14px;
        img {
            width: 41px;
            height: 41px;
        }
        label {
            font-size: 33px;
        }
    }
    .heroSection {
        grid-template-columns: 54% 46%;
        .heroImage {
            width: 100%;
        }
        .mainTitle {
            font-size: 58px;
            margin-bottom: 20px;
        }
        .signupTitle {
            font-size: 30px;
        }
        .usdc {
            font-size: 32px;
        }
        .socialMediaSection {
            margin-top: 32px;
        }
    }
}

@media (min-width: 1200.98px) and (max-width: 1399.98px) {
    .container {
        max-width: 920px;
    }
    .main {
        top: 26px;
    }
    .gradient-background {
        height: 436px;
    }
    .promiseCard {
        height: 260px;
    }
    .downloadBtn {
        width: 340px;
        height: 79px;
        .bg-white {
            margin-top: 30px;
        }
    }
    .header {
        padding: 10px 0px;
    }
    .appStoreSection {
        padding: 12px 12px 12px 14px;
        img {
            width: 36px;
            height: 36px;
        }
        label {
            font-size: 26px;
        }
    }
    .heroSection {
        grid-template-columns: 54% 46%;
        .heroImage {
            width: 96%;
        }
        .mainTitle {
            font-size: 48px;
            margin-bottom: 20px;
        }
        .signupTitle {
            font-size: 24px;
        }
        .usdc {
            font-size: 28px;
        }
        .socialMediaSection {
            margin-top: 32px;
        }
    }
    .socialMediaSection {
        .youtube,
        .twitter,
        .discord {
            padding: 8px 20px 8px 8px !important;
            margin-right: 10px;
            font-size: 16px;
            span {
                margin-left: 10px;
            }
        }
    }
}

@media (min-width: 1024.98px) and (max-width: 1200.98px) {
    .container {
        max-width: 960px;
    }
    .gradient-background {
        height: 420px;
    }
    .downloadBtn {
        width: 330px;
        .bg-white {
            margin-top: 30px;
        }
    }
    .header {
        padding: 10px 0px;
    }
    .appStoreSection {
        padding: 12px 12px 12px 14px;
        img {
            width: 32px;
            height: 32px;
        }
        label {
            font-size: 24px;
        }
    }
    .heroSection {
        grid-template-columns: 54% 46%;
        .heroImage {
            width: 96%;
        }
        .mainTitle {
            font-size: 48px;
            margin-bottom: 20px;
        }
        .signupTitle {
            font-size: 26px;
        }
        .usdc {
            font-size: 28px;
        }
        .socialMediaSection {
            margin-top: 32px;
        }
    }
}

@media (min-width: 991.98px) and (max-width: 1136.98px) {
    .container {
        max-width: 960px;
    }
    .gradient-background {
        height: 480px;
    }
    .downloadBtn {
        width: 330px;
        .bg-white {
            margin-top: 30px;
        }
    }
    .header {
        padding: 10px 0px;
    }
    .appStoreSection {
        padding: 12px 12px 12px 14px;
        img {
            width: 32px;
            height: 32px;
        }
        label {
            font-size: 24px;
        }
    }
    .heroSection {
        grid-template-columns: 54% 46%;
        .heroImage {
            width: 96%;
        }
        .mainTitle {
            font-size: 48px;
            margin-bottom: 20px;
        }
        .signupTitle {
            font-size: 26px;
        }
        .usdc {
            font-size: 28px;
        }
        .socialMediaSection {
            margin-top: 32px;
        }
    }
}

@media (min-width: 767.98px) and (max-width: 991.98px) {
    video {
        width: 50%;
    }
    footer {
        img {
            width: 36px !important;
            margin-right: 14px;
        }
        .quickLinks a {
            font-size: 14px !important;
        }
    }

    .gradient-background {
        height: 350px;
    }
    .downloadBtn {
        .bg-white {
            width: 310px;
            margin-top: 20px;
        }
        .appStoreSection {
            padding: 9px 12px 9px 14px !important;
            img {
                width: 28px;
                height: 28px;
            }
            label {
                font-size: 23px;
            }
        }
    }
    .socialMediaSection {
        .youtube,
        .twitter,
        .discord {
            padding: 8px 20px 8px 8px !important;
            margin-right: 10px;
        }
    }
    .header {
        .logo {
            width: 130px;
        }
        .litePaper {
            font-size: 14px;
        }
    }
    .heroSection {
        grid-template-columns: 54% 46%;
        .heroImage {
            width: 96%;
        }
        .mainTitle {
            font-size: 38px;
            margin-bottom: 20px;
        }
        .signupTitle {
            font-size: 22px;
        }
        .usdc {
            font-size: 26px;
        }
        .socialMediaSection {
            margin-top: 22px;
        }
    }
    .featuredGames {
        margin-top: 0px;
    }
    .navLinks {
        p {
            font-size: 17px !important;
        }
    }
    .main {
        top: 26px;
    }
}

@media (min-width: 575.98px) and (max-width: 767.98px) {
    .gradient-background {
        height: 480px;
    }
    .socialMediaSection {
        .youtube,
        .twitter,
        .discord {
            padding: 8px 20px 8px 8px !important;
            margin-right: 10px;
        }
    }
    .bgImageHeroHeader {
        padding-top: 0px;
    }
    .header {
        padding: 14px 12px 0px 10px;
        .logo {
            width: 120px;
        }
        .litepaperMenu {
            margin-top: -7px;
            padding-right: 0.25rem;
            .litePaper {
                border: 2px solid #fff;
                border-radius: 63px;
                color: #fff;
                font-size: 14px;
                padding: 3px 16px 5px;
                text-decoration: none;
                margin-top: 0;
                margin-right: 16px;
            }
        }
        .hamburgerMenu {
            margin-top: -3px;
        }
    }
    .mobileHeroSection .gamesImage {
        width: 40%;
        margin: auto;
    }
}

@media (max-width: 575.98px) {
    .gradient-background {
        height: 480px;
    }
    .socialMediaSection {
        .youtube,
        .twitter,
        .discord {
            padding: 8px 20px 8px 8px !important;
            margin-right: 10px;
        }
    }
    .headerMain .heroSection .mainTitle {
        margin-left: 0px;
        font-size: 35px;
    }
    .usdc {
        font-size: 18px;
    }
    .signupTitle {
        font-size: 18px !important;
        margin-top: 15px !important;
        color: #fff !important;
    }
    .header {
        padding: 10px 12px 0px 10px;
        .logo {
            width: 120px;
        }
        .litepaperMenu {
            margin-top: -7px;
            padding-right: 0.25rem;
            .litePaper {
                border: 2px solid #fff;
                border-radius: 63px;
                color: #fff;
                font-size: 14px;
                padding: 3px 16px 5px;
                text-decoration: none;
                margin-top: 0;
                margin-right: 16px;
            }
        }
        .hamburgerMenu {
            margin-top: -3px;
        }
    }
}

.afterDownloadButtonHome {
    background-color: #fff;
    position: sticky;
    top: 0px;
    box-shadow: 0px 6px 8px 0px #00000014;
    padding: 19px 0 16px;
    transition: all 0.6s cubic-bezier(0.19, 1, 0.22, 1);
    box-shadow: 0 1px 3px rgb(0 0 0 / 10%);
    z-index: 10;
    padding-bottom: 12px !important;
    padding-top: 15px !important;
    .logo {
        width: 170px;
        margin-top: 2px;
    }
    .litePaper {
        color: #c328fb;
        border-radius: 63px;
        padding: 8px 26px 10px;
        text-decoration: none;
        border: 2px solid #c328fb;
        font-size: 18px;
        font-weight: 600;
        margin-top: -10px;
    }
    a {
        text-decoration: none;
    }
    .navLinks {
        a,
        p {
            color: #212121;
            font-size: 22px;
            font-family: "BROMEGAMEDIUM";
            &:hover {
                color: #c328fb;
            }
        }
    }
}

.mobileSideMenu {
    .modal {
        position: fixed;
        top: 0;
        right: 0 !important;
        bottom: 0;
        z-index: 1050;
        display: none;
        overflow: hidden;
        outline: 0;
    }
    .modal-dialog {
        transform: none !important;
        left: initial;
        .modal-header {
            margin: 0;
            border-bottom: 0;
        }
        .modal-title {
            .showCategories {
                font-size: 20px;
                font-weight: bold;
                text-transform: initial;
                margin-bottom: 0;
            }
        }
    }
}

.aboutSection {
    p {
        font-family: "BROMEGAREGULAR";
        font-size: 18px;
        color: #696969;
        @media screen and (max-width: 500px) {
            font-size: 18px;
        }
    }
    b {
        font-family: "BROMEGAREGULAR";
        font-size: 18px;
        color: #696969;
        @media screen and (max-width: 500px) {
            font-size: 18px;
        }
    }
    ul {
        li,
        b {
            font-size: 18px;
            font-family: "BROMEGAREGULAR";
            color: dimgray;
        }
    }
}
