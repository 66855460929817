.policies {
    a {
        text-decoration: none;
    }
    .mainheading {
        font-size: 35px;
        font-family: "BROMEGABOLD";
        margin-bottom: 4px;
        text-align: center;
        color: #333333;
    }

    .lastUpdate {
        font-family: "BROMEGAMEDIUM";
        color: #000;
        text-align: center;
    }

    .subHeading {
        font-size: 25px;
        font-family: "BROMEGABOLD";
        margin-bottom: 10px;
    }

    .pText {
        font-family: "BROMEGAREGULAR";
        font-size: 18px;
        margin-bottom: 4px;
        color: #696969;
        letter-spacing: -0.4px;
    }

    .detailedSection {
        font-family: "BROMEGAREGULAR";
        p {
            font-size: 18px;
        }
    }

    ul {
        padding-left: 20px;
        margin-top: 20px;
        letter-spacing: -0.4px;
        li {
            font-size: 18px;
            color: #323232;
            font-family: "BROMEGASEMIBOLD";
            letter-spacing: -0.4px;
            ol {
                letter-spacing: -0.4px;
                margin-top: 10px;
                li {
                    letter-spacing: -0.4px;
                    font-family: "BROMEGAREGULAR";
                    margin-bottom: 10px;
                    font-size: 17px;
                    color: #696969;
                }
            }
        }
    }

    a {
        color: #c328fb;
    }

    .accordion {
        margin-bottom: 10px;
        border-bottom: 1px solid #d1cece70;
    }

    .accordion-button:focus,
    .accordion-button:active {
        box-shadow: none;
    }

    .accordion-button:not(.collapsed) {
        color: #212529;
        box-shadow: none;
        background-color: transparent;
        font-family: "BROMEGABOLD";
    }

    .accordion-header {
        margin-bottom: 0;
    }

    .accordion {
        button {
            font-family: "BROMEGAREGULAR";
        }
        .accordion-body {
            color: #696969;
            font-family: "BROMEGAREGULAR";
            padding-top: 0;
            b {
                font-family: "BROMEGAMEDIUM";
            }
        }
    }
}

.headerSectionPolicies {
    .litePaper {
        color: #c328fb;
        border-radius: 63px;
        padding: 6px 22px 8px;
        text-decoration: none;
        border: 2px solid #c328fb;
        font-size: 18px;
        font-weight: 600;
        margin-top: -10px;
    }
    .logo {
        width: 170px;
    }
    a {
        text-decoration: none;
    }
    .navLinks {
        a,
        p {
            color: #696969;
            font-size: 22px;
            font-family: "BROMEGAMEDIUM";
            &:hover {
                color: #c328fb;
            }
        }
    }
    a {
        text-decoration: none;
    }
}

.afterDownloadButtonHome {
    background-color: #fff;
    position: sticky;
    top: 0px;
    box-shadow: 0px 6px 8px 0px #00000014;
    padding: 19px 0 16px;
    transition: all 0.6s cubic-bezier(0.19, 1, 0.22, 1);
    box-shadow: 0 1px 3px rgb(0 0 0 / 10%);
    z-index: 10;
    padding-bottom: 12px !important;
    padding-top: 15px !important;
    .logo {
        width: 170px;
        margin-top: 2px;
    }
    .litePaper {
        color: #c328fb;
        border-radius: 63px;
        padding: 8px 26px 10px;
        text-decoration: none;
        border: 2px solid #c328fb;
        font-size: 18px;
        font-weight: 600;
        margin-top: -10px;
    }
    a {
        text-decoration: none;
    }
    .navLinks {
        a,
        p {
            color: #696969;
            font-size: 22px;
            font-family: "BROMEGAMEDIUM";
            &:hover {
                color: #c328fb;
            }
        }
    }
}

@media (max-width: 767.98px) {
    .heroSectionMain {
        .heroLargeSection {
            .signupTitle {
                // color: #323232;
                font-size: 18px;
                margin-top: 20px;
                p {
                    font-family: "BROMEGAREGULAR";
                    .usdc {
                        color: #1d9df7;
                    }
                    .clhTokens {
                        color: #b223fc;
                    }
                }
            }
            .heroImage {
                width: 70%;
                margin: auto;
            }
            .mainTitle {
                font-size: 35px;
                font-family: "BROMEGABOLD";
            }
        }
    }
}

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
    .bgImageHeroHeader {
        padding-top: 0px;
    }
    .headerSectionPolicies {
        padding: 14px 12px 0px 10px;
        .logo {
            width: 120px;
        }
        .litepaperMenu {
            margin-top: -7px;
            padding-right: 0.25rem;
            .litePaper {
                border: 2px solid #c328fb;
                border-radius: 63px;
                color: #c328fb;
                font-size: 14px;
                padding: 3px 16px 5px;
                text-decoration: none;
                margin-top: 0;
                margin-right: 16px;
            }
        }
        .hamburgerMenu {
            margin-top: -3px;
            filter: brightness(0.1);
        }
    }
}

// Small devices (landscape phones, less than 768px)
@media (min-width: 575.98px) and (max-width: 767.98px) {
    .headerSectionPolicies {
        padding: 14px 12px 0px 10px;
        .logo {
            width: 120px;
        }
        .litepaperMenu {
            margin-top: -7px;
            padding-right: 0.25rem;
            .litePaper {
                border: 2px solid #c328fb;
                border-radius: 63px;
                color: #c328fb;
                font-size: 14px;
                padding: 3px 16px 5px;
                text-decoration: none;
                margin-top: 0;
                margin-right: 16px;
            }
        }
        .hamburgerMenu {
            filter: brightness(0.1);
            margin-top: -3px;
        }
    }
}

// Medium devices (tablets, less than 992px)
@media (min-width: 767.98px) and (max-width: 991.98px) {
    .headerSectionPolicies {
        .logo {
            width: 140px;
        }
        .litePaper {
            font-size: 14px;
        }
    }
}

// Large devices (desktops, less than 1200px)
@media (min-width: 991.98px) and (max-width: 1199.98px) {
    .headerSectionPolicies {
        .logo {
            width: 140px;
        }
        .navLinks {
            a,
            p {
                font-size: 18px;
            }
        }
    }
}

// X-Large devices (large desktops, less than 1400px)
@media (min-width: 1199.98px) and (max-width: 1399.98px) {
    .headerSectionPolicies {
        .logo {
            width: 150px;
        }
        .navLinks {
            a,
            p {
                font-size: 18px;
            }
        }
    }
}

@media (min-width: 1399.98px) {
    .afterDownloadButtonHome {
        .headerSectionPolicies {
            padding-bottom: 8px !important;
            padding-top: 10px !important;
            .logo {
                width: 170px;
            }
        }
    }
    .bgImageHeroHeader {
        padding-top: 0;
    }
}

@media (min-width: 1900.98px) {
    .policies .pText {
        font-size: 22px;
    }
}

@media (min-width: 1399.98px) and (max-width: 1900.98px) {
    .headerSectionPolicies {
        padding-bottom: 8px !important;
        padding-top: 10px !important;
        .logo {
            width: 170px;
        }
        .navLinks {
            a,
            p {
                font-size: 20px;
            }
        }
    }
}
